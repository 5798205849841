import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height"},[_c(VRow,{attrs:{"row":"","wrap":""}},[_c(VCol,{attrs:{"cols":"12","sm":"4","offset-sm":"4"}},[_c(VCard,{attrs:{"elevation":"12"}},[_c(VCardTitle,{staticClass:"title mb-5"},[_vm._v("\n          "+_vm._s(_vm.$t('auth.login.title'))+"\n        ")]),_vm._v(" "),_c(VCardText,[(_vm.$route.query.passwordReset === 'true')?_c(VAlert,{attrs:{"type":"success"}},[_vm._v("\n            "+_vm._s(_vm.$t('auth.login.password_reset_success'))+"\n          ")]):_vm._e(),_vm._v(" "),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"filled":"","error-messages":errors,"label":_vm.$t('auth.login.labels.email'),"type":"email","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:4","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"filled":"","error-messages":errors,"label":_vm.$t('auth.login.labels.password')},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_vm._v(" "),_c(VCheckbox,{attrs:{"label":_vm.$t('auth.login.labels.stay_connected')},model:{value:(_vm.stayConnected),callback:function ($$v) {_vm.stayConnected=$$v},expression:"stayConnected"}}),_vm._v(" "),_c('div',{staticClass:"btn"},[_c(VBtn,{staticClass:"white--text",attrs:{"loading":_vm.isLoading,"type":"submit","color":"#EB237F","rounded":""}},[_vm._v("\n                  "+_vm._s(_vm.$t('auth.login.labels.submit'))+"\n                ")])],1)],1),_vm._v(" "),(_vm.hasError)?_c(VAlert,{staticClass:"mt-3 mb-0",attrs:{"type":"error","transition":"slide-y-reverse-transition"}},[(_vm.isUserInputError)?_c('span',{domProps:{"textContent":_vm._s(_vm.$t(("errors.user." + _vm.error)))}}):_c('DisplayErrorNoPadding',{attrs:{"error":_vm.error,"do-not-worry":""}})],1):_vm._e()]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }