<template>
  <div>
    <!-- the only difference with DisplayError -->
    <!-- is this <div> that does not have any padding -->
    <span v-if="text" v-text="text" />
    <span
      v-else
      v-text="error ? $t(`errors.known.${error}`) : $('errors.unknown')"
    />

    <br v-if="withLineReturn" />

    <span v-if="doNotWorry" v-text="$t('errors.do-not-worry')" />

    <v-btn v-if="retriable" small @click="$emit('retry')">
      {{ $t('global.retry') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: null
    },
    error: {
      type: String,
      default: null
    },
    withLineReturn: {
      type: Boolean,
      default: false
    },
    doNotWorry: {
      type: Boolean,
      default: false
    },
    retriable: {
      type: Boolean,
      default: false
    }
  }
}
</script>
