<template>
  <v-container class="fill-height">
    <v-row row wrap>
      <v-col cols="12" sm="4" offset-sm="4">
        <v-card elevation="12">
          <v-card-title class="title mb-5">
            {{ $t('auth.login.title') }}
          </v-card-title>

          <v-card-text>
            <v-alert
              v-if="$route.query.passwordReset === 'true'"
              type="success"
            >
              {{ $t('auth.login.password_reset_success') }}
            </v-alert>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form method="post" @submit.prevent="handleSubmit(onSubmit)">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
                  slim
                >
                  <v-text-field
                    v-model="email"
                    filled
                    :error-messages="errors"
                    :label="$t('auth.login.labels.email')"
                    type="email"
                    required
                  />
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="password"
                  rules="required|min:4"
                  slim
                >
                  <v-text-field
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    filled
                    :error-messages="errors"
                    :label="$t('auth.login.labels.password')"
                    @click:append="showPassword = !showPassword"
                  />
                </ValidationProvider>
                <v-checkbox
                  v-model="stayConnected"
                  :label="$t('auth.login.labels.stay_connected')"
                />
                <div class="btn">
                  <v-btn
                    :loading="isLoading"
                    type="submit"
                    color="#EB237F"
                    class="white--text"
                    rounded
                  >
                    {{ $t('auth.login.labels.submit') }}
                  </v-btn>
                </div>
              </form>

              <v-alert
                v-if="hasError"
                class="mt-3 mb-0"
                type="error"
                transition="slide-y-reverse-transition"
              >
                <span
                  v-if="isUserInputError"
                  v-text="$t(`errors.user.${error}`)"
                />
                <DisplayErrorNoPadding v-else :error="error" do-not-worry />
              </v-alert>
            </ValidationObserver>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- <v-dialog v-model="isDialogOpen" width="500">
      <template #activator="{ on }">
        <a v-on="on">Mot de passe oublié ?</a>
      </template>
      <v-card flat class="dialog">
        <v-card-title class="headline grey">
          Mot de passe oublié
        </v-card-title>
        <v-card-text>
          Un e-mail va vous être envoyé pour réinitialiser votre mot de passe
          <br />
          <br />
          <v-text-field
            v-model="email"
            type="email"
            label="Adresse e-mail"
            :error-messages="emailForgotten.errorEmail"
          />
          <v-alert
            type="error"
            :value="emailForgotten.isSuccess === false"
            transition="slide-y-reverse-transition"
          >
            {{ emailForgotten.errorMessage }}
          </v-alert>
          <v-alert
            type="success"
            :value="emailForgotten.isSuccess === true"
            transition="slide-y-reverse-transition"
          >
            Un mail vous a été envoyé
          </v-alert>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn @click="isDialogOpen = false">
            Annuler
          </v-btn>
          <v-btn
            color="success"
            :loading="emailForgotten.isLoading"
            @click="askPasswordReset"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import DisplayErrorNoPadding from '@/components/layouts/DisplayErrorNoPadding'

export default {
  layout: 'login',
  components: {
    DisplayErrorNoPadding,
    ValidationObserver,
    ValidationProvider
  },
  data: () => ({
    showPassword: false,
    email: '',
    password: '',
    stayConnected: false
  }),
  computed: {
    ...mapState('auth', ['isLoading', 'error', 'isUserInputError']),
    ...mapGetters('auth', ['hasError'])
  },
  methods: {
    ...mapActions('auth', ['login']),

    onSubmit() {
      this.login({
        email: this.email,
        password: this.password,
        stayConnected: this.stayConnected,
        redirect: this.$route.query.r
      })
    }
  }
}
</script>
